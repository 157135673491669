<!--
 * @Author: lbh
 * @Date: 2022-06-17 09:18:07
 * @LastEditors: lbh
 * @LastEditTime: 2023-11-14 15:56:49
 * @Description: 頂部 bannar 編輯
-->
<template>
  <div class="top-bannar-edit">
    <div class="cell-b">
      <h3>組件配置</h3>
      <el-select
        v-model="value"
        placeholder="選擇跳轉頁面"
      >
        <selfPagePicker v-model="configs.go" />
      </el-select>

      <div class="cell-b">
        <selfCell title="設置導航欄顏色">
          <el-color-picker
            v-model="configs.tabColor"
            @change="setValue('tabColor')"
          />
        </selfCell>
      </div>

      <div class="edit-title">
        <h3>標題設置</h3>
        <div class="cell-b">
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="configs[`${lItem.code=='HK'?'':lItem.code}title`]"
            :placeholder="`請輸入標題-${lItem.name}`"
            @input="setValue(`${lItem.code=='HK'?'':lItem.code}title`)"
            type="textarea"
            rows="3"
          ></el-input>
        </div>
      </div>

      <div class="edit-image">
        <h3>圖片設置</h3>
        <selfUpload
          v-model="configs.src"
          @change="setValue('src')"
        />
      </div>

      <selfCell title="輸入圖片描述">
        <el-input
          v-model="configs.alt"
          type="textarea"
          @change="setValue('alt')"
        />
      </selfCell>
    </div>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
export default {
  name: "editTopBannar",
  mixins: [langEditMixin],
  props: {
    type: {
      default () {
        return "title";
      }
    },
    configs: {
      default () {
        return {
          src: "",
          title: ""
        }
      }
    },

  },
  data () {
    return {
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      value: ""
    }
  },
  methods: {
    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs[key] })
    }
  }
}
</script>

<style lang="less" scoped>
</style>